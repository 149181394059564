import { Injectable } from "@angular/core";
import { gql, Query } from "apollo-angular";
import { checkAeUserNameAvailability, checkAeUserNameAvailabilityVariables } from "../../../../schema/graphql-types";

@Injectable({ providedIn: 'root' })
export class CheckUsernameAvailabilityGQL extends Query<checkAeUserNameAvailability, checkAeUserNameAvailabilityVariables> {
    document = gql` 
    query checkAeUserNameAvailability($username: String!) {
            checkUsernameAvailability(userName: $username) {
                isAvailable
        }
    }`;
}
