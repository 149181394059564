import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { searchOrganizationsForTeacher, searchOrganizationsForTeacherVariables } from '../../../../schema/graphql-types';

@Injectable({ providedIn: 'root' })
export class SearchOrgGQL extends Query<searchOrganizationsForTeacher, searchOrganizationsForTeacherVariables>  {
    document = gql`
    query searchOrganizationsForTeacher (
        $orgName: String!,
        $orgSearchType: String!,
        $orgPostalCode: String,
        $searchLimit: Int,
        $orgState: String,
        $orgCity: String,
        $orgCountry: String,
        $sapCustomerId: String) {
        searchOrganizations(orgSearchRequest: {
            orgName: $orgName,
            orgSearchType: $orgSearchType,
            orgPostalCode: $orgPostalCode,
            searchLimit: $searchLimit,
            orgState: $orgState,
            orgCity: $orgCity,
            orgCountry: $orgCountry,
            sapCustomerId: $sapCustomerId
        }){
            name,
            address1,
            address2,
            city,
            state,
            zipcode,
            country,
            organizationId,
            displayName,
            organizationType
            orgSettings {
                selfRegistration
            }
        }
    }
`;
}
