import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable, map } from 'rxjs';
import { SEARCH_ORGANIZATIONS } from '../../queries/graphql';
import { searchOrganizations,
  searchOrganizations_searchOrganizations as Organizations,
  searchOrganizationsVariables as SearchOrgRequest
} from '../../../../schema/graphql-types';

@Injectable()
export class SearchOrgService {

  constructor(
    private apollo: Apollo) {
  }

  searchOrgs(searchOrgRequest: SearchOrgRequest): Observable<Organizations[]> {
    const orgName = searchOrgRequest.orgName;
    const orgSearchType = searchOrgRequest.orgSearchType;
    const orgPostalCode = searchOrgRequest.orgPostalCode;
    const orgState = searchOrgRequest.orgState;
    const orgCity = searchOrgRequest.orgCity;
    const orgCountry = searchOrgRequest.orgCountry;

    return this.apollo.query<searchOrganizations>({
        query: SEARCH_ORGANIZATIONS,
        variables: {
          orgName,
          orgSearchType,
          orgPostalCode,
          orgState,
          orgCity,
          orgCountry,
        }
    }).pipe(
        map(({ data }: ApolloQueryResult<searchOrganizations>) => {
            return data.searchOrganizations;
    }));
  }
}
