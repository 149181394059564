import {Injectable} from "@angular/core";
import {gql, Mutation} from "apollo-angular";
import {
    GetUserAccount, GetUserAccountVariables,
} from "../../../../schema/graphql-types";

@Injectable({providedIn: 'root'})
export class UserAccountGQL extends Mutation<GetUserAccount, GetUserAccountVariables> {
    document = gql`
    mutation GetUserAccount ($email: String!, $organizationId: String!) {
        getUserAccount(email: $email, organizationId: $organizationId) {
            userAccounts {
                userName,
                name,
                schoolName,
                userId,
                organizationId,
                registrationId,
                userStatus,
                createdDate
            },
            message,
            statusCode,
        }
    }
    `;
}
