import {Action, createReducer, on} from '@ngrx/store';
import * as TeacherRegistrationAction from '../actions/teacher.registration.actions';
import {TeacherRegistrationInitialState, TeacherRegistrationState} from "../state/teacher.registration.state";

const teacherRegReducer = createReducer(TeacherRegistrationInitialState,
    on(TeacherRegistrationAction.loadTeacherRegistrationAction, (state, action) => ({
        ...state, ...action.payload
    }))
);

export function teacherRegistrationReducer(state: TeacherRegistrationState, action: Action): TeacherRegistrationState {
    return teacherRegReducer(state, action);
}
