import {Injectable} from "@angular/core";
import {gql, Mutation} from "apollo-angular";
import {TeacherRegistration, TeacherRegistrationVariables} from "../../../../schema/graphql-types";

@Injectable({ providedIn: 'root' })
export class TeacherRegistrationGQL extends Mutation<TeacherRegistration, TeacherRegistrationVariables> {
    document = gql`
    mutation TeacherRegistration($teacherRegistrationRequest: TeacherRegistrationRequest!) {
        teacherRegistration(teacherRegistrationRequest: $teacherRegistrationRequest) {
            response,
            registrationId,
            autoVerificationMode,
            autoVerification,
            skipTeacherVerification,
            teacherExists,
            adminExists,
            registrationExists,
            status,
            message,
            userExists,
            role,
            firstName,
            lastName,
            email,
            statusCode,
            userDeleted
        }
    }
    `;
}
