import gql from 'graphql-tag';

export const SEARCH_ORGANIZATIONS = gql `
    query searchOrganizations (
        $orgName: String!,
        $orgSearchType: String!,
        $orgPostalCode: String!,
        $searchLimit: Int,
        $orgState: String,
        $orgCity: String,
        $orgCountry: String,
        $sapCustomerId: String) {
        searchOrganizations(orgSearchRequest: {
            orgName: $orgName,
            orgSearchType: $orgSearchType,
            orgPostalCode: $orgPostalCode,
            searchLimit: $searchLimit,
            orgState: $orgState,
            orgCity: $orgCity,
            orgCountry: $orgCountry,
            sapCustomerId: $sapCustomerId
        }){
            name,
            address1,
            address2,
            city,
            state,
            zipcode,
            country,
            organizationId,
            displayName,
            organizationType
            orgSettings {
                selfRegistration
            }
        }
    }
`;

export const SELF_REGISTRATION = gql `
    mutation selfRegistration (
        $email: String!,
        $firstName: String!,
        $lastName: String!,
        $orgID: String!,
        $orgName: String!,
        $userRole: String!,
        $userId: String,
        $userName: String,
        $orgType: String,
        $recaptchaResponse: String,
        $policy: [Policy!]!,
    ){
    selfRegistration(userAccountRequest:
        {
            email: $email,
            firstName: $firstName,
            lastName: $lastName,
            organizationId: $orgID,
            organizationName: $orgName,
            userRole: $userRole,
            userId: $userId,
            userName: $userName,
            orgType: $orgType,
            recaptchaResponse: $recaptchaResponse,
            policy: $policy,
        }){
            response
                {
                    registrationId
                    message
                    status
                    userExists
                    role
                    adminExists
                    registrationExists
                    response
                    firstName
                    lastName
                    email
                    requestDate
                    accessRequestedType
                }
            status
            statusCode
        }
    }
`;

export const ACTIVE_ADMINS_COUNT = gql`
    query getActiveAdminsCount (
        $organizationId: String!,
        $userRole: String!) {
            getActiveAdminsCount (organizationId: $organizationId, userRole: $userRole) {
                count
                statusCode
                status
        }
    }
`;

export const SF_SELF_REGISTRATION = gql `
    mutation salesforceSelfRegistration (
        $email: String!,
        $firstName: String!,
        $lastName: String!,
        $orgInfo: OrganizationInput!,
        $organizationUrl: String,
        $recaptchaResponse: String,
        $superintendent: ContactInfo,
        $techDirector: ContactInfo,
        $principal: ContactInfo,
        $policy: [Policy!]!
    ){
    salesforceSelfRegistration(sfRegistrationRequest:
        {
            email: $email,
            firstName: $firstName,
            lastName: $lastName,
            organizationInfo: $orgInfo,
            organizationUrl: $organizationUrl,
            recaptchaResponse: $recaptchaResponse,
            superintendent: $superintendent,
            techDirector: $techDirector,
            principal: $principal,
            policy: $policy,
        }){
            salesforceCaseNumber
            status
            statusCode
            firstName
            lastName
            email
        }
    }
`;

export const GET_ALL_USER_POLICIES = gql`
    query getAllUserPolicies {
        getAllUserPolicies {
            userPolicies{
                policyId
                longTitle
                description
                defaultPolicy
                active
              }
              status
              statusCode
        }
    }
`;
