import { OrganizationInformation } from "../../../admin-self-registration/organization-search/organizationInformation";

export interface TeacherRegistrationState {
    organizationSearchRequest?: IOrganizationSearchRequest,
    organizationInformation?: OrganizationInformation;
    teacherInformation?: ITeacherInformation;
    userRegistrationResponse?: IUserRegistrationResponse;
    teacherEmail?: string;
}

export const TeacherRegistrationInitialState: TeacherRegistrationState = {
    organizationSearchRequest: null,
    organizationInformation: null,
    teacherInformation: null,
    userRegistrationResponse: null,
};

export interface AbstractTeacherRegistrationState {
    teacherRegistration: TeacherRegistrationState;
}

export interface IOrganizationSearchRequest {
    orgName?: string,
    orgPostalCode?: string,
    orgCity?: string,
    orgState?: string,
    orgCountry?: string
}

export interface ITeacherInformation {
    firstName?: string,
    lastName?: string,
    email?: string,
}

export interface IUserRegistrationResponse {
    adminExists?: boolean;
    teacherExists?: boolean;
    address1?: string;
    address2?: string;
    countryCode?: string;
    city?: string;
    stateCode?: string;
    postalCode?: string;
    settings?: Settings;
    registration?: Registration;
    message?: string;
    statusCode?: string;
    status?: string;
}

export class Settings {
    dateLastModified?: string;
    isDeleted?: string;
    districtId?: string;
    emails?: string[];
    userEmail?: string;
    userId?: string;
    userFullName?: string;
    role?: string;
    existingTeacherVerification?: boolean;
    teacherDomainVerification?: boolean;
    teacherMDRVerification?: boolean;
    domainCheck?: boolean;
}

export class Registration {
    registrationId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    organizationName?: string;
    organizationId?: string;
    autoVerified?: boolean;
    userId?: string;
    districtOrgId?: string;
    verificationCode?: string;
    skipTeacherVerification?: boolean;
    expirationDate?: string;
    status?:string
}
