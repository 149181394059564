import { Injectable } from "@angular/core";
import { gql, Mutation } from "apollo-angular";
import {
    ResendVerifyTeacherRegistration, ResendVerifyTeacherRegistrationVariables
} from "../../../../schema/graphql-types";

@Injectable({ providedIn: 'root' })
export class ResendVerifyTeacherRegistrationGQL extends Mutation<ResendVerifyTeacherRegistration, ResendVerifyTeacherRegistrationVariables>{
    document = gql`
      mutation ResendVerifyTeacherRegistration($resendVerificationRequest: SendVerificationCodeRequest!) {
        resendVerifyUserRegistration(resendVerificationRequest: $resendVerificationRequest) {
            response,
            registrationId,
            autoVerificationMode,
            autoVerification,
            skipTeacherVerification,
            teacherExists,
            adminExists,
            registrationExists,
            status,
            message,
            userExists,
            role,
            statusCode
        }
    }
    `;
}
