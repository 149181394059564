import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements as lstSelfRegisterFormLoader} from '@lst/lst-self-register-form/dist/loader';
import { defineCustomElements as lstDropDownLoader } from '@lst/lst-dropdown/dist/loader';
import { defineCustomElements as lstTooltipLoader } from '@lst/lst-tooltip/dist/loader';
import { defineCustomElements as celComponentsLoader } from "@savvaslearning/cel-components/dist-stencil/loader";

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
lstSelfRegisterFormLoader(window);
lstDropDownLoader(window);
lstTooltipLoader(window);
celComponentsLoader();
