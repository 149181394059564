import { NgModule } from '@angular/core';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from '../../../environments/environment';

@NgModule({
    exports: [
        ApolloModule
    ]
})
export class GraphQLModule {

    constructor(
        private apollo: Apollo,
        private httpLink: HttpLink) {

        const uri = environment.aggregationUrl;
        apollo.create({
            link: httpLink.create({ uri }),
            cache: new InMemoryCache()
        });
    }
}
