<header class="header">
    <nav class="navbar navbar-light">
        <span class="header-logo-link">
            <a target="_blank" [href]="savvasUrl">
                <img class="header__logo" src="../assets/logos/savvas-dark.svg" alt="EasyBridge logo"
                    data-e2e-id="easybridgeLogo" />
            </a>
        </span>
        <a target="_blank" class="header__link" [href]="supportAndTrainingUrl"
            data-e2e-id="supportAndTraining">
            <span class="support-text">{{'header.supportLinkText' | translate}}</span>
            <cel-icon class="link-icon" data-name="maximize">
            </cel-icon>
        </a>
    </nav>
</header>
