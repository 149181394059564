import { Component } from '@angular/core';
import { links } from '../../environments/common.config';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    supportAndTrainingUrl: string;
    savvasUrl: string;

    constructor() {
        this.supportAndTrainingUrl = links.savvasSupportUrl;
        this.savvasUrl = environment.savvasUrl;
    }

}
