import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { catchError, Subject, takeUntil, throwError } from 'rxjs';
import { environment } from "../../../environments/environment";
import { NavigateTo, TeacherSelfRegistrationConstants } from '../constants/teacher-registration.constants';
import { loadTeacherRegistrationAction } from "../store/actions/teacher.registration.actions";
import * as fromStore from '../store/reducers';
import { TeacherRegistrationState } from "../store/state/teacher.registration.state";
import { TeacherRegistrationService } from "./teacher-registration.service";
import { OrganizationInformation } from "../../admin-self-registration/organization-search/organizationInformation";

@Injectable({
    providedIn: 'root'
})
export class TeacherRegistrationDetailsService {
    private _onDestroy$: Subject<void> = new Subject<void>();
    public registrationDetails: TeacherRegistrationState = {
        organizationInformation: null,
        teacherInformation: null,
        userRegistrationResponse: null,
        teacherEmail: null,
        organizationSearchRequest: null
    };
    public isAllowedToNavigatePrevPage: boolean = false;
    public navigateToRequestAccountPage: boolean = false;
    public isRegistrationCompleted: boolean = false;
    public isInvitedTeacher: boolean = false;
    public loadingSubject$ = new Subject<boolean>();

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly teacherRegistrationStore: Store<TeacherRegistrationState>,
        private readonly teacherRegistrationService: TeacherRegistrationService) {
    }

    public handleSuccessRegistration(registrationResponse) {
        this.loadingSubject$.next(true);
        this.getRegistrationResponseAndPushToStore(registrationResponse.registrationId);
        setTimeout(() => {
            this.registrationDetails.userRegistrationResponse = {
                ...this.registrationDetails.userRegistrationResponse,
                teacherExists: registrationResponse.teacherExists,
                adminExists: registrationResponse.adminExists
            };
            if (this.requestApproved(registrationResponse)) {
                this.loadingSubject$.next(false);
                this.router.navigate(["teacherselfregistration/" + NavigateTo.createUsername,
                registrationResponse.registrationId], { relativeTo: this.activatedRoute });
                return;
            } else if (!registrationResponse.adminExists && ((!this.registrationDetails.userRegistrationResponse.settings.existingTeacherVerification)
                || (this.registrationDetails.userRegistrationResponse.settings.existingTeacherVerification && !this.registrationDetails.userRegistrationResponse.teacherExists))) {
                this.router.navigate(["teacherselfregistration/" + NavigateTo.verifyUserPage, registrationResponse.registrationId], { relativeTo: this.activatedRoute });
                return;
            } else {
                this.loadingSubject$.next(false);
                this.router.navigate(["teacherselfregistration/" + NavigateTo.approveTeacher,
                registrationResponse.registrationId], { queryParams: { hasAdmin: registrationResponse.adminExists }, relativeTo: this.activatedRoute });
            }
        }, 2000);
    }

    public isDisplayGroupNotAvailable(registrationResponse): boolean {
        return this.isInternalEmailDomain(registrationResponse.email) && !registrationResponse.skipTeacherVerification;
    }

    private handleRegistrationError(registrationId: string) {
        this.router.navigate(['../../error', registrationId,
            TeacherSelfRegistrationConstants.fromPage.verifyEmailPage], { relativeTo: this.activatedRoute });
    }

    private requestApproved(registrationResponse) {
        return registrationResponse.skipTeacherVerification || registrationResponse.autoVerification ||
            registrationResponse.autoVerified ||
            TeacherSelfRegistrationConstants.registrationStatus.approvedCode === registrationResponse.status ||
            TeacherSelfRegistrationConstants.registrationStatus.invitedStatus === registrationResponse.status;
    }

    public checkUserStatus(registrationResponse): void {
        const registrationId = registrationResponse.registrationId ?
            registrationResponse.registrationId : registrationResponse.registration.registrationId;
        const registrationStatus = registrationResponse.status ?
            registrationResponse.status : registrationResponse.registration.status;
        this.getRegistrationResponseAndPushToStore(registrationId);
        const status = TeacherSelfRegistrationConstants.registrationStatus;
        switch (registrationStatus) {
            case status.createdErrCode:
                this.router.navigateByUrl(
                    `/teacherselfregistration/${NavigateTo.alreadyExistingAccount}/${registrationId}`);
                break;
            case status.provisionalStatus:
                this.redirectToTMSUser(registrationId);
                break;
            case status.requestedStatus:
                this.router.navigate(
                    ["teacherselfregistration/" + NavigateTo.requestedRegistration, registrationId],
                    { relativeTo: this.activatedRoute });
                break;
            case status.incompleteStatus:
                this.router.navigate(
                    ["teacherselfregistration/" + NavigateTo.incompleteRegistration, registrationId],
                    { relativeTo: this.activatedRoute });
                break;
            case status.approvedCode:
            case status.invitedStatus:
                this.router.navigate(
                    ["teacherselfregistration/" + NavigateTo.createUsername, registrationId],
                    { relativeTo: this.activatedRoute });
                break;
            case status.bouncedStatus:
                this.router.navigate(["teacherselfregistration/" + NavigateTo.undeliveredEmail],
                    { relativeTo: this.activatedRoute });
                break;
            default:
                break;
        }
    };

    private redirectToTMSUser(registrationId: string): void {
        this.teacherRegistrationService.getUserRegistration(registrationId)
            .pipe(catchError(err => {
                return throwError(() => this.handleRegistrationError(registrationId));
            }), takeUntil(this._onDestroy$))
            .subscribe({
                next: (userRegistrationResponse) => {
                    if (userRegistrationResponse && userRegistrationResponse.data) {
                        const registrationResponse = userRegistrationResponse.data.getUserRegistration;
                        if (!registrationResponse.registration.teacherAssistance &&
                            !registrationResponse.registration.verificationAssistance) {
                            this.router.navigate(["teacherselfregistration/" + NavigateTo.incompleteRegistration,
                            registrationResponse.registration.registrationId], { relativeTo: this.activatedRoute });
                        } else {
                            this.router.navigateByUrl(`/teacherselfregistration/${NavigateTo.alreadyExistingAccount}/${registrationResponse.registration.registrationId}`);
                        }
                    }
                },
                error: () => {
                    this.navigateToErrorPage();
                }
            });
    }

    public isInternalEmailDomain(email: string): boolean {
        const internalEmailDomain = environment.emailDomain;
        const emailDomain = email.substring(email.indexOf(TeacherSelfRegistrationConstants.emailSeparator) + 1, email.length);
        return emailDomain.indexOf(internalEmailDomain) > -1;
    };

    private navigateToErrorPage() {
        this.router.navigate([NavigateTo.error, "0", TeacherSelfRegistrationConstants.fromPage.confirmPage],
            { relativeTo: this.activatedRoute });
    }

    private navigateToApprovalErrorPage(registrationId: string) {
        this.router.navigate(['teacherselfregistration/' + NavigateTo.error, registrationId,
        TeacherSelfRegistrationConstants.fromPage.approvalpage], { relativeTo: this.activatedRoute });
    }

    public getUserRegistrationRequest(userRegistrationResponse) {
        if (userRegistrationResponse && userRegistrationResponse.registration &&
            userRegistrationResponse.registration.registrationId) {
            return {
                organizationId: userRegistrationResponse.registration.organizationId,
                organizationName: userRegistrationResponse.registration.organizationName,
                lastName: userRegistrationResponse.registration.lastName,
                firstName: userRegistrationResponse.registration.firstName,
                email: userRegistrationResponse.registration.email,
                userRegistrationId: userRegistrationResponse.registration.registrationId,
                districtId: userRegistrationResponse.registration.districtOrgId,
                adminExists: userRegistrationResponse.adminExists,
                teacherExists: userRegistrationResponse.teacherExists,
                autoVerification: userRegistrationResponse.registration.autoVerified,
                userId: userRegistrationResponse.registration.userId,
                skipTeacherVerification: userRegistrationResponse.registration.autoVerified
            };
        }
    }

    getRegistrationResponseAndPushToStore(registrationId: string): void {
        this.teacherRegistrationService.getUserRegistration(registrationId)
            .pipe(catchError(err => {
                return throwError(() => this.navigateToErrorPage());
            }), takeUntil(this._onDestroy$))
            .subscribe({
                next: (userRegistrationResponse) => {
                    if (userRegistrationResponse && userRegistrationResponse.data) {
                        const registrationResponse = userRegistrationResponse.data.getUserRegistration;
                        if (registrationResponse && registrationResponse.registration &&
                            registrationResponse.registration.registrationId) {
                            const payload = this.constructRegistrationDetailsPayload(registrationResponse);
                            this.teacherRegistrationStore.dispatch(loadTeacherRegistrationAction({ payload }));
                            this.loadRegistrationDetailsFromStore();
                        }
                    }
                },
                error: () => {
                    this.navigateToErrorPage();
                }
            });
    }

    pushRegistrationResponseToStore(registrationResponse) {
        const payload = this.constructRegistrationDetailsPayload(registrationResponse);
        this.teacherRegistrationStore.dispatch(loadTeacherRegistrationAction({ payload }));
        this.loadRegistrationDetailsFromStore();
    }

    constructRegistrationDetailsPayload(registrationResponse) {
        return {
            organizationSearchRequest: {
                orgName: registrationResponse.registration.organizationName,
                orgPostalCode: registrationResponse.postalCode,
                orgCity: registrationResponse.city,
                orgState: registrationResponse.stateCode,
                orgCountry: registrationResponse.countryCode,
                orgType: TeacherSelfRegistrationConstants.orgType.schoolOnly
            },
            organizationInformation: {
                orgID: registrationResponse.registration.organizationId,
                orgName: registrationResponse.registration.organizationName,
                orgAddress: registrationResponse.address2 ?
                    registrationResponse.address1 + ', ' + registrationResponse.address2 : registrationResponse.address1,
                orgCity: registrationResponse.city,
                orgState: registrationResponse.stateCode,
                orgPostalCode: registrationResponse.postalCode,
                orgCountry: registrationResponse.countryCode,
                orgType: TeacherSelfRegistrationConstants.orgType.schoolOnly,
                districtOrgId: registrationResponse.registration.districtOrgId,
                isTSREnabled: true
            },
            teacherInformation: {
                firstName: registrationResponse.registration.firstName,
                lastName: registrationResponse.registration.lastName,
                email: registrationResponse.registration.email
            },
            userRegistrationResponse: {
                registration: {
                    registrationId: registrationResponse.registration.registrationId,
                    organizationId: registrationResponse.registration.organizationId,
                    organizationName: registrationResponse.registration.organizationName,
                    firstName: registrationResponse.registration.firstName,
                    lastName: registrationResponse.registration.lastName,
                    email: registrationResponse.registration.email,
                    districtOrgId: registrationResponse.registration.districtOrgId,
                    autoVerified: registrationResponse.registration.autoVerified,
                    userId: registrationResponse.registration.userId,
                    skipTeacherVerification: registrationResponse.registration.skipTeacherVerification,
                    status: registrationResponse.registration.status,
                    expirationDate: registrationResponse.registration.expirationDate
                },
                adminExists: registrationResponse.adminExists,
                teacherExists: registrationResponse.teacherExists,
                settings: registrationResponse.settings
            }
        };
    }

    public loadRegistrationDetailsFromStore() {
        this.teacherRegistrationStore.select(fromStore.getTeacherRegistration).pipe(
            takeUntil(this._onDestroy$)).subscribe((registrationDetails) => {
                if (registrationDetails) {
                    this.registrationDetails = {
                        organizationSearchRequest: registrationDetails.organizationSearchRequest,
                        organizationInformation: registrationDetails.organizationInformation,
                        teacherInformation: registrationDetails.teacherInformation,
                        userRegistrationResponse: registrationDetails.userRegistrationResponse
                    }
                }
            });
    }

    clearRegistrationDetailsFromStore(): void {
        const payload = {
            organizationSearchRequest: null,
            organizationInformation: null,
            teacherInformation: null,
            userRegistrationResponse: null,
            teacherInvite: null
        };
        this.teacherRegistrationStore.dispatch(loadTeacherRegistrationAction({ payload }));
        this.loadRegistrationDetailsFromStore();
    }


    navigateToEmailVerificationPage(registrationId) {
        this.getRegistrationResponseAndPushToStore(registrationId);
        setTimeout(() => {
            this.router.navigate(["teacherselfregistration/" + NavigateTo.verifyEmail, registrationId],
                { relativeTo: this.activatedRoute });
        }, 2000);
    }

    navigateToIncompletePage(registrationId) {
        setTimeout(() => {
            this.router.navigate(["teacherselfregistration/" + NavigateTo.incompleteRegistration, registrationId],
                { relativeTo: this.activatedRoute });
        }, 2000);
    }
    navigateToRequestedPage(registrationId) {
        setTimeout(() => {
            this.router.navigate(["teacherselfregistration/" + NavigateTo.requestedRegistration, registrationId],
                { relativeTo: this.activatedRoute });
        }, 2000);
    }

    sendRequestToVerifier(verifierDetails): void {
        const registrationId = this.registrationDetails.userRegistrationResponse.registration.registrationId
        this.teacherRegistrationService.sendVerifierRequest(registrationId,
            verifierDetails.adminEmail, verifierDetails.orgUrl).pipe(catchError(err => {
                return throwError(() =>
                    this.navigateToApprovalErrorPage(registrationId));
            }), takeUntil(this._onDestroy$))
            .subscribe({
                next: ({ data }) => {
                    if (this.registrationDetails.userRegistrationResponse.status === ('provisionalStatus')) {
                        this.router.navigate(["teacherselfregistration/" + NavigateTo.createUsername,
                            registrationId], { relativeTo: this.activatedRoute });
                        return;
                    }
                    if (!data.verificationAssistance.usOrganization) {
                        if (data.verificationAssistance.adminExists) {
                            // TODO: navigate to VA Request Sent page
                        } else {
                            // TODO: navigate to local Support
                        }
                    } else {
                        this.createSalesForceTicket(verifierDetails);
                    }
                },
                error: () => {
                    this.navigateToApprovalErrorPage(registrationId);
                }
            });
    }

    createSalesForceTicket(verifierDetails): void {
        const registrationId = this.registrationDetails.userRegistrationResponse.registration.registrationId
        const salesForceTicketParams = {
            email: this.registrationDetails.userRegistrationResponse.registration.email,
            teacherName: this.registrationDetails.userRegistrationResponse.registration.firstName +
                this.registrationDetails.userRegistrationResponse.registration.lastName,
            verifyAdminEmail: verifierDetails.adminEmail,
            verifyUrl: verifierDetails.orgUrl,
            teacherSchool: this.registrationDetails.userRegistrationResponse.registration.organizationName,
            organizationId: this.registrationDetails.userRegistrationResponse.registration.organizationId,
            districtId: this.registrationDetails.userRegistrationResponse.registration.districtOrgId
        };
        this.teacherRegistrationService.createSalesForceTicket(salesForceTicketParams).pipe(catchError(err => {
            return throwError(() =>
                this.navigateToApprovalErrorPage(registrationId));
        }), takeUntil(this._onDestroy$))
            .subscribe({
                next: ({ data }) => {
                    if (!!data.createSalesForceTicket) {
                        this.registrationDetails.teacherEmail = verifierDetails.adminEmail;
                        this.router.navigate(["teacherselfregistration/" + NavigateTo.confirmTeacherRequestApproval], { relativeTo: this.activatedRoute });
                    } else {
                        this.navigateToApprovalErrorPage(registrationId);
                    }
                },
                error: () => {
                    this.navigateToApprovalErrorPage(registrationId);
                }
            });
    }

    isTeacherInviteExpired(expirationDate: string): boolean {
        const date = Date.now();
        return date > Number(expirationDate);
    }

    checkTeacherInviteStatus(registrationResponse, isRegExists = false): boolean {
        if (registrationResponse.registration.expirationDate &&
            this.isTeacherInviteExpired(registrationResponse.registration.expirationDate)) {
            this.router.navigate([`/teacherselfregistration/invitation-expire`],
                { relativeTo: this.activatedRoute });
            return true;
        }
        else if (TeacherSelfRegistrationConstants.registrationStatus.rejectedErrCode === registrationResponse.registration.status) {
            this.router.navigate([`/teacherselfregistration/cancel-invitation`],
                { relativeTo: this.activatedRoute });
            return true;
        } else if (TeacherSelfRegistrationConstants.registrationStatus.createdCode === registrationResponse.registration.status && !isRegExists) {
            this.router.navigate([`/teacherselfregistration/already-account-exist`, registrationResponse.registration.registrationId],
                { relativeTo: this.activatedRoute });
            return true;
        }
        return false;
    }

    checkRegStatus(registrationResponse): void {
        if (TeacherSelfRegistrationConstants.registrationStatus.incompleteStatus === registrationResponse.registration.status) {
            this.router.navigate([`/teacherselfregistration/incompleteRegistration`, registrationResponse.registration.registrationId],
                { relativeTo: this.activatedRoute });
        } else if (TeacherSelfRegistrationConstants.registrationStatus.requestedStatus === registrationResponse.registration.status) {
            this.router.navigate([`/teacherselfregistration/requestedRegistration`, registrationResponse.registration.registrationId],
                { relativeTo: this.activatedRoute });
        }
    }
}
