import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import {
    SELF_REGISTRATION,
    ACTIVE_ADMINS_COUNT,
    SF_SELF_REGISTRATION,
    GET_ALL_USER_POLICIES
} from '../../queries/graphql';
import {
    selfRegistration,
    selfRegistration_selfRegistration as SelfRegistrationResponse,
    getActiveAdminsCount,
    getActiveAdminsCount_getActiveAdminsCount,
    salesforceSelfRegistration,
    salesforceSelfRegistration_salesforceSelfRegistration as SFRegistrationResponse,
    OrganizationInput,
    getAllUserPolicies_getAllUserPolicies,
    getAllUserPolicies
} from '../../../../schema/graphql-types';
import { RegistrationInfo } from './registration-info.service';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable({
    providedIn: 'root'
})
export class SelfRegistrationService {

    constructor(
        private apollo: Apollo) { }

    static readonly customer_admin = 'customer_administrator';

    selfRegistration(selfRegistrationRequest: RegistrationInfo): Observable<SelfRegistrationResponse> {
        const { email, firstName, lastName, recaptchaResponse, organizationInfo, policy } = selfRegistrationRequest;
        const { orgID, orgName, orgType} = organizationInfo;
        const userRole = SelfRegistrationService.customer_admin;

        return this.apollo.mutate<selfRegistration>({
            mutation: SELF_REGISTRATION,
            variables: {
                email,
                firstName,
                lastName,
                orgID,
                orgName,
                userRole,
                orgType,
                recaptchaResponse,
                policy
            }
        }).pipe(
            map(({ data }: ApolloQueryResult<selfRegistration>) => {
                return data.selfRegistration;
            }));
    }

    getActiveAdmins(organizationId: string): Observable<getActiveAdminsCount_getActiveAdminsCount> {
        const userRole = SelfRegistrationService.customer_admin;
        return this.apollo.query<getActiveAdminsCount>({
            query: ACTIVE_ADMINS_COUNT,
            variables: {
                organizationId,
                userRole
            }
        }).pipe(
            map(({ data }: ApolloQueryResult<getActiveAdminsCount>) => {
                return data.getActiveAdminsCount;
            }));
    }

    sfSelfRegistration(selfRegistrationRequest: RegistrationInfo): Observable<SFRegistrationResponse> {
        const { email, firstName, lastName, recaptchaResponse, organizationInfo, sfRegInfo, policy } =
            selfRegistrationRequest;
        const { superintendent, techDirector, principal } = sfRegInfo;
        let organizationUrl;
        if (sfRegInfo.schoolURL) {
            organizationUrl = sfRegInfo.schoolURL.orgURL;
        } else {
            organizationUrl = sfRegInfo.districtURL.orgURL;
        }
        const orgInfo: OrganizationInput = {
            name: organizationInfo.orgName,
            address: organizationInfo.orgAddress,
            city: organizationInfo.orgCity,
            state: organizationInfo.orgName,
            postalCode: organizationInfo.orgPostalCode,
            country: organizationInfo.orgCountry,
            id: organizationInfo.orgID,
            type: organizationInfo.orgType,
        };
        return this.apollo.mutate<salesforceSelfRegistration>({
            mutation: SF_SELF_REGISTRATION,
            variables: {
                email,
                firstName,
                lastName,
                orgInfo,
                organizationUrl,
                recaptchaResponse,
                superintendent,
                techDirector,
                principal,
                policy
            }
        }).pipe(
            map(({ data }: ApolloQueryResult<salesforceSelfRegistration>) => {
                return data.salesforceSelfRegistration;
            }));
    }

    getAllUserPolicies(): Observable<getAllUserPolicies_getAllUserPolicies> {
        return this.apollo.query<getAllUserPolicies>({
            query: GET_ALL_USER_POLICIES,
        }).pipe(
            map(({ data }: ApolloQueryResult<getAllUserPolicies>) => {
                return data.getAllUserPolicies;
            }));
    }
}
