import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountRegistrationPageComponent } from './shared/components/account-registration-page/account-registration-page.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'adminselfregistration',
        pathMatch: 'full'
    },
    {
        path: 'register',
        component: AccountRegistrationPageComponent
    },
    {
        path: 'adminselfregistration',
        // eslint-disable-next-line max-len
        loadChildren: () => import('./admin-self-registration/admin-self-registration.module').then(m => m.AdminSelfRegistrationModule)
    },
    {
        path: 'teacherselfregistration',
        // eslint-disable-next-line max-len
        loadChildren: () => import('./teacher-self-registration/teacher-self-registration.module').then(m => m.TeacherSelfRegistrationModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})

export class AppRoutingModule { }
