import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import {AbstractTeacherRegistrationState, TeacherRegistrationState} from "../state/teacher.registration.state";
import * as fromTeacherRegistration from './teacher.registration.reducer';

export const teacherRegistrationFeatureKey = 'teacherRegistration';

const selectTeacherRegistration = createFeatureSelector<TeacherRegistrationState>(teacherRegistrationFeatureKey);

export const getTeacherRegistration = createSelector(selectTeacherRegistration, (state: TeacherRegistrationState) => {
    return {
        organizationSearchRequest: state.organizationSearchRequest,
        organizationInformation: state.organizationInformation,
        teacherInformation: state.teacherInformation,
        userRegistrationResponse: state.userRegistrationResponse,
    };
});

export const teacherRegistrationReducers: ActionReducerMap<AbstractTeacherRegistrationState> = {
    teacherRegistration: fromTeacherRegistration.teacherRegistrationReducer,
};
