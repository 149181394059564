import { commonConfig, IEnvironment, telemetryConfig } from './common.config';

export const environment: IEnvironment = {
    ...commonConfig,
    envName: 'nightly',
    envUrl: 'https://self-registration-web.nightly.savvasrealizedev.com/',
    referrerAppUrl: 'https://self-registration-web.nightly.savvasrealizedev.com/',
    aggregationUrl: 'https://srs-bff-service.nightly.savvasrealizedev.com/graphql',
    telemetryConfig: {
        ...telemetryConfig,
        telemetryKey: 'zFdk1xZo8wappsnFgnyE18Mj01I1y6GsEhQ63IQj',
        telemetryUrl: 'https://api.realizedev-test.com/telemetry-cat-nightly/lst-telemetry',
    },
    ssoUrl: 'https://nightly-sso.rumba.pk12ls.com/sso',
    catUrl: 'https://nightly-cat.easybridge.pk12ls.com/ca',
    loginUrl: 'https://nightly-sso.rumba.pk12ls.com/sso/login?profile=eb&service=https://nightly-cat.easybridge.pk12ls.com/ca/dashboard.htm&additionalAttributes=Domains&k12int=true',
    forgotPasswordLink: 'https://nightly-sso.rumba.pk12ls.com/sso/forgot?service=https://nightly-cat.easybridge.pk12ls.com',
    schoolAdminVerificationAssistanceUrl: 'https://self-registration-web.nightly.savvasrealizedev.com/adminselfregistration/sfschooladminrequest',
    districtAdminVerificationAssistanceUrl: 'https://self-registration-web.nightly.savvasrealizedev.com/adminselfregistration/sfdistadminrequest'
};
