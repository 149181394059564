<div class="footer-wrap">
    <cel-footer *ngIf="footerItems"
        [ngClass]="footerClass"
        [copyrightLabel]="footerItems?.copyRightText"
        [agreementLabel]="footerItems?.userAgreement.label"
        [privacyLabel]="footerItems?.privacyPolicy.label"
        [agreementHref]="footerItems?.userAgreement.url"
        [privacyHref]="footerItems?.privacyPolicy.url"
        [creditsHref]="footerItems?.credits.url"
        [revisionTag]="footerItems?.revisionTag"
        [showRevisionTag]="true"
        [showCredits]="true"
        [size]="device">
    </cel-footer>
</div>
