import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

export let browserRefresh = false;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
    title = 'Self Registration';
    isIFrame = false;
    private _onDestroy: Subject<void> = new Subject<void>();

    constructor(private router: Router) {
        router.events.pipe(takeUntil(this._onDestroy)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                browserRefresh = !router.navigated;
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy.complete();
    }
}
