// Angular
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { SearchOrgService } from './shared/services/search-org.service';
import { SelfRegistrationService } from './shared/services/self-registration.service';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// EB App
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { GraphQLModule } from './shared/config/apollo.config';
import { LOCATION, SESSION_STORE } from './app.tokens';
import { SharedModule } from './shared/shared.module';
import { RegistrationInfoService } from './shared/services/registration-info.service';
import { OrganizationSearchFilterService } from './shared/services/organization-search-filter.service';
import { CustomTranslateLoader } from './shared/translate/custom-translate-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {StoreModule} from "@ngrx/store";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        GraphQLModule,
        StoreModule.forRoot({}, {}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoader,
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        { provide: LOCATION, useValue: window.location },
        { provide: SESSION_STORE, useValue: sessionStorage },
        { provide: 'WINDOW',  useValue: window },
        Apollo,
        OrganizationSearchFilterService,
        SearchOrgService,
        SelfRegistrationService,
        RegistrationInfoService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
