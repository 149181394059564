import {Injectable} from "@angular/core";
import {gql, Mutation} from "apollo-angular";
import {VerifyTeacherRegistration, VerifyTeacherRegistrationVariables} from "../../../../schema/graphql-types";

@Injectable({providedIn: 'root'})
export class VerifyTeacherRegistrationGQL extends Mutation<VerifyTeacherRegistration, VerifyTeacherRegistrationVariables> {
    document = gql`
      mutation VerifyTeacherRegistration($verificationCode: String!,
                                         $teacherRegistrationRequest: TeacherRegistrationRequest!) {
        verifyTeacherRegistration(verificationCode: $verificationCode,
                                  teacherRegistrationRequest:$teacherRegistrationRequest) {
            response,
            registrationId,
            autoVerificationMode,
            autoVerification,
            skipTeacherVerification,
            teacherExists,
            adminExists,
            registrationExists,
            status,
            message,
            userExists,
            role,
            firstName,
            lastName,
            email,
            statusCode,
        }
    }
    `;
}

