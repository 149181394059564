import { Injectable } from '@angular/core';
import { OrganizationInformation } from '../../admin-self-registration/organization-search/organizationInformation';
import { Policy } from '../../../../schema/graphql-types';

@Injectable({
    providedIn: 'root'
})

export class ContactInfo {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export class OrgURL {
    orgURL: String;
}

export class SFSelfRegInformation {
    schoolURL: OrgURL;
    districtURL: OrgURL;
    superintendent: ContactInfo;
    techDirector: ContactInfo;
    principal: ContactInfo;
}

export class RegistrationInfo {
    email: string;
    firstName: string;
    lastName: string;
    policy: Policy[];
    recaptchaResponse?: string;
    organizationInfo: OrganizationInformation;
    sfRegInfo?: SFSelfRegInformation;
}

export enum SelfRegistrationResponseCode {
    success = 'success',
    userExists = 'userExists',
    registrationExists = 'registrationExists',
    noActiveAdmin = 'noAcitveAdmin',
    otherErrors = 'otherErrors',
}

export enum ORG_TYPE {
    SCHOOL = 'School',
    DISTRICT = 'District'
}

export class RegistrationInfoService {
    registrationInfo: RegistrationInfo = new RegistrationInfo();
    private selfRegistrationResponseCode: SelfRegistrationResponseCode;
    private registrationId: string;
    private requestDate: string;

    constructor() { }

    setUserInfo(firstName: string, lastName: string, email: string): void {
        this.registrationInfo.firstName = firstName;
        this.registrationInfo.lastName = lastName;
        this.registrationInfo.email = email;
    }

    setOrgInfo(orgInfo: OrganizationInformation): void {
        this.registrationInfo.organizationInfo = orgInfo;
    }

    setRecaptchaResponse(recaptchaResponse: string): void {
        this.registrationInfo.recaptchaResponse = recaptchaResponse;
    }

    getSelfRegistrationInfo(): RegistrationInfo {
        return this.registrationInfo;
    }

    setSelfRegistrationResponseCode(result: SelfRegistrationResponseCode): void {
        this.selfRegistrationResponseCode = result;
    }

    setSelfRegistrationId(registrationId: string): void {
        this.registrationId = registrationId;
    }

    setRequestDate(requestDate: string): void {
        this.requestDate = requestDate;
    }

    getRequestDate(): string {
        return this.requestDate;
    }

    getSelfRegistrationResponseCode(): SelfRegistrationResponseCode {
        return this.selfRegistrationResponseCode;
    }

    getSelfRegistrationId(): string {
        return this.registrationId;
    }

    getSFRegInfo(): SFSelfRegInformation {
        return this.registrationInfo.sfRegInfo;
    }

    setSFRegInfo(sfRegInfo: SFSelfRegInformation) {
        this.registrationInfo.sfRegInfo = sfRegInfo;
    }

    setUserPolicies(policy: Policy[]) {
        this.registrationInfo.policy = policy;
    }

    getUserPolicies(): Policy[] {
        return this.registrationInfo.policy;
    }

    sort(policies: Policy[]): Policy[] {
        return policies.sort((a, b) => a.longTitle > b.longTitle ? 1 : -1);
    }
}

