import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { links } from '../../environments/common.config';

enum CelFooterSize {
    DESKTOP = "desktop",
    TABLET = "tablet",
    MOBILE = "mobile"
}

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    footerItems = {};
    footerClass = '';
    passLocale = 'en';
    device = CelFooterSize.DESKTOP;
    currentYear: number = new Date().getFullYear();

    constructor(
        private readonly translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        @Inject('WINDOW') private readonly windowRef: Window
    ) {
    }

    ngOnInit(): void {
        this.initializePageLayout();
        if (this.getLocale()) {
            this.footerClass = 'platform_footer';
            this.footerItems = {
                copyRightText: this.translate.instant('footer.footerCopyright') + this.currentYear + this.translate.instant('footer.footerText'),
                revisionTag: this.translate.instant('footer.revisionTag'),
                userAgreement: {
                    url: links.userAgreementUrl,
                    label: this.translate.instant('footer.userAgreement')
                },
                privacyPolicy: {
                    url: links.privacyPolicyUrl,
                    label: this.translate.instant('footer.privacyPolicy')
                },
                credits: {
                    url: links.creditsUrl
                }
            };
        }
    }

    getLocale(): string {
        this.activatedRoute.queryParams.subscribe(params => {
            const queryParamLocale = params.locale;
            if (queryParamLocale) {
                this.passLocale = queryParamLocale;
            }
        });
        return this.passLocale;
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.initializePageLayout();
    }

    initializePageLayout(): void {
        this.device = CelFooterSize.TABLET;
        if (this.windowRef.innerWidth > 1023) {
            this.device = CelFooterSize.DESKTOP;
        } if (this.windowRef.innerWidth < 577) {
            this.device = CelFooterSize.MOBILE;
        }
    }

}
