import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOCATION } from '../../app.tokens';

@Injectable()
export class NavigationUtilsService {

    constructor(
        @Inject(LOCATION) private locationRef: Location,
        private router: Router
    ) { }

    navigateToError(error?: Error, url?: string) {
        const route = url ? url : '/error';
        this.navigateToRoute(route);
    }

    navigateToRoute(route: string) {
        this.router.navigate([route]);
    }

    navigateToUrl(url: string) {
        this.locationRef.href = url;
    }
}
