import { Injectable } from "@angular/core";
import { gql, Mutation } from "apollo-angular";
import { SendTeacherApproval, SendTeacherApprovalVariables } from "../../../../schema/graphql-types";

@Injectable({ providedIn: 'root' })
export class SendTeacherRequestGQL extends Mutation<SendTeacherApproval, SendTeacherApprovalVariables> {
  document = gql`
    mutation SendTeacherApproval($registrationId: String!, $email: String!) {
        teacherVerification(registrationId: $registrationId, email: $email) {
          registrationId
          userId
          status
          organizationId
          districtOrgId
          statusCode
        }
    }
    `;
}
