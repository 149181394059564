export class OrganizationSearchFilter {
    orgType: string;
    orgName: string;
    orgCity: string;
    orgState: string;
    orgPostalCode: string;
    orgCountry: string;
}

export class OrganizationSearchFilterService {

    organizationSearchFilter: OrganizationSearchFilter = new OrganizationSearchFilter();

    constructor() { }

    setOrganizationSearchFilter({ orgType, orgName, orgCity, orgState, orgPostalCode, orgCountry}): void {
        this.organizationSearchFilter.orgType = orgType;
        this.organizationSearchFilter.orgName = orgName;
        this.organizationSearchFilter.orgCity = orgCity;
        this.organizationSearchFilter.orgState = orgState;
        this.organizationSearchFilter.orgPostalCode = orgPostalCode;
        this.organizationSearchFilter.orgCountry = orgCountry;
    }

    getOrganizationSearchFilter(): OrganizationSearchFilter {
        return this.organizationSearchFilter;
    }
}
