
// this file holds config that is common across all envs,
// these configs can also be overridden on env specific config

export interface IRecaptchaConfig {
    siteKey: string;
    orgIdsToSkipRecaptchaFor: string[];
}

export interface ITelemetry {
    telemetryKey: string;
    telemetryUrl: string;
    platform: string;
}

export interface ICommonConfig {
    production: boolean;
    referrerAppUrl: string;
    referrerAppLandingPageSubpath: string;
    recaptchaConfig: IRecaptchaConfig;
    customerAdminRole: string;
    telemetryConfig: ITelemetry;
    salesforceAdminRegistrationURL: string;
    savvasUrl: string;
    emailDomain: string;
    oldSelfReg: string;
}

export interface IEnvironment extends ICommonConfig {
    envName: string;
    envUrl?: string;
    aggregationUrl: string;
    ssoUrl: string;
    catUrl: string;
    loginUrl?: string;
    forgotPasswordLink?: string;
    schoolAdminVerificationAssistanceUrl?: string;
    districtAdminVerificationAssistanceUrl?: string;
}

export const recaptchaConfig: IRecaptchaConfig = {
    siteKey: '6LdfxfAUAAAAACdNWVupA2nUHpbObDW_ueolJsNC',
    orgIdsToSkipRecaptchaFor: ['8a97b1cf692b37ce016950ae243d05ec',
        '8a97b1ce692b37e4016950aeecdc0678',
        '8a97b1ce692b37e4016950ae7b230674',
        '8a97b1cc692b367f016950af2f140685',
        '8a97b1ce692b37e4016a0e32400630bb',
        '8a97b1ce692b37e4016a0e33bfc730be',
        '8a97b1a7692b3760016a0e34bcab3029',
        '8a97b1ce692b37e4016a0e352f7a30c5',
        '8a97b1a7692b3760016a0e35f3df302e',
        '8a97b1cd693974ff016a0e368b172cfb',
        '8a97b1a6668d0b040166c70d05bc10f1',
        '8a97b1cc668d0bea0166c70faec010bd',
        '8a97b1cd6775bd550167a8fa01350d1b',
        '8a97b1cd65b54e680166228a8f6317cb',
        '8a97b1cd693974ff016a329abbaf3511',
        '8a97b1cf692b37ce016a329c693e37a3',
        '8a97b1a7692b3760016a329bb4883846',
        '8a97b1a6692b377d016a329cccd13825',
        '8a97b1a7692b3760016a32a2e1a4384b',
        '8a97b1a6692b377d016a32a3e8273829',
        '8a97b1cf692b37ce016a32a382f937a8',
        '8a97b1ce692b37e4016a32a4359a38b2',
        '8a97b1ce692b37e4016a32a5fc1b38b7',
        '8a97b1cf692b37ce016a32a6743d37ac',
        '8a97b1a6692b377d016a32a6d632382d',
        '8a97b1cf692b37ce016a32a739de37b0',
        '8a97b1a6692b377d016a32a784243831',
        '8a97b1ce692b37e4016a4be65f803ddd',
        '8a97b1cf692b37ce016a4be827c43ca7',
        '8a97b1cf692b37ce016a4be71ee63ca3',
        '8a97b1cf692b37ce016a4be8bf9b3cab',
        '8a97b1a6692b377d016a4be916233da0',
        '8a720ac5702aada10170bec2c9ad110d',
        '8a72034d7057c4170170bec2d7c70d5e',
        '8a7208df708118ee0170bec2e073044e',
        '8a720ac5702aada10170bec3bd651110',
        '8a720ac5702aada10170bec3cddc1113',
        '8a7206d8702aae9e0170bec3d67b116b',
        '8a720ac5702aada10170bec787061116',
        '8a7206d8702aae9e0170bec79532116e',
        '8a72034d7057c4170170bec79deb0d61',
        '8a720ac5702aada10170bec823731119',
        '8a72052c707cbe740170bec830ae0713',
        '8a720ac5702aada10170bec83936111c',
        '8a7205d18163292201816afbb2810055',
        '8a7200f0816329ab01816b2636190030',
        '8a720a6a816329e501816bbb3e010031',
        '8a720b5e8163293901816be099fd002f',
        '8a72056b816329cb01816c0e3023002c',
        '8a720b85816329f701816bbf46bf002a',
        '8a720b068163291601816bc4a32c0037',
        '8a720a6a816329e501816bc5d42d0034',
        '8a720a6a816329e501816be260360038',
        '8a7206a4816329fa01816be532690020',
        '8a720a6a816329e501816be9618c003b',
        '8a7206928163291d01816c0ff0510039',
        '8a72056b816329cb01816c1308a5002f',
        '8a720b85816329f701816c140134002d',
        '8a720b5e81632939018184cf946b010b',
        '8a720a6a816329e5018184d02e4b0103',
        '8a7206a4816329fa018184d1152d00dd',
        '8a720531816329d5018184d1d65600bc',
        '8a7206928163291d01816be988d30036',
        '8a720b068163291601816bfc3c6b003b',
        '8a720531816329d501816bea45740027',
        '8a720066816329cd01816bf5db21002d',
        '8a7200468163290901816b83e4180038',
        '8a7206a4816329fa01817af24f1d0097',
        '8a72056b816329cb01817afaa3f000c2',
        '8a7205d18163292201817afbf81c00ba',
        '8a7208a781632a7801817b4a5d4600c8',
        '8a720066816329cd01817b4cf8830094',
        '8a720b5e8163293901817b4e8bad00be',
        '8a7206928163291d01817f0ddef500d0',
        '8a720a6a816329e501817f10ef5100cc',
        '8a97b1a6668d0b040166c70d05bc10f1',
        '8a720b8b764498910176890b018f0689',
        '8a7205fa81850fa301818644a5a00024',
        '8a7202988185107601818649a67a001b',
        '8a7206a48185104a0181864f129c002e',
        '8a7202988185107601818654c204001e',
        '8a7206a48185104a01818644abb8002b',
        '8a720384818510650181865027000024',
        '8a7202268185105201818645cc4e0024',
        '8a7202c285236e27018529be91920003',
        '8a72084385a238b10185a9df3b1e03a6',
        '8a720ae585245d8f018529cc44e9000a',
        '8a72006585c5e9680185c91dc8dd00b6',
        '8a72006585c5e9680185c92b831500ce',
        '8a72006585c5e9680185c92e341300d3',
        '8a72006585c5e9680185c927639c00c4',
        '8a720140851c287b018530a601790026',
        '8a72028d851cfe70018530a679ce0026',
        '8a7203c785314653018533706b120079',
        '8a7203c7853146530185337096610085',
        '8a7203c78531465301853389a7ea00ba',
        '8a7203c78531465301853389f12300c6',
        '8a7203c785314653018538a4830201cb',
        '8a7203c785314653018538a4d79001d7',
        '8a7202518524716a01852fad106f0010',
        '8a720429851c1a3701852fa1bbb40025',
        '8a72006585c5e9680185c9711ee300df',
        '8a7203c7853146530185336aef54006e',
        '8a72064a850fcadb01851086dd3d002e',
        '8a7202c285236e27018529c6d9880006',
        '8a720755851e349701852ecd3bd20016',
        '8a720b5486b5fe6e0186b64b04e90000',
        '8a7207c786b1b3910186b64c88c6001b',
        '8a7206d486b5fe6d0186b64976bb0000',
        '8a72086586b5fdad0186b647f4e90000',
        '8a7203c7853146530185339a931c00f2',
        '8a7204988653cb9a018654a10a870019',
        '8a720136841f604201842db3a77c0092',
        '8a7203b186d92bcd0186d991a9e7235d',
        '8a7206b386d9cad40186dafd24420000',
        '8a720429851c1a37018529bd44a7000e',
        '8a7208a78524153e018529bda7030008',
        '8a72091c85259eb9018529be0e9a0009',
        '8a7208067ca9556f017cb77d3f840010',
        '8a7208067ca9556f017cb77d80700013',
        '8a97b1ce692b37e4016a0e32400630bb',
        '8a97b1a7692b3760016a0e35f3df302e',
        '8a97b1ce692b37e4016a0e352f7a30c5',
        '8a7203c78531465301853370433d0072',
        '8a7203c785314653018533707949007d',
        '8a7203c785314653018533705bde0075',
        '8a72019a7f90ca06017f93b74fc2005d',
        '8a7203ba7f90cacd017f93b769740049',
        '8a7204547ca95465017cb76553a40015',
        '8a7201dc8dac5a1c018dac5b46270000',
        '8a7207d28dbcb6e2018dc06c26230003',
        '8a7207d28dbcb6e2018dc55ed6400006',
        '8a72079d8dc2fbae018dc5eb1f770007'
    ],
};

export const telemetryConfig: ITelemetry = {
    telemetryKey: 'D7c9TnjHef23Wl84Ga0t66XgYiRIkxCMaiTLR9D0',
    telemetryUrl: 'https://api.realizedev-test.com/telemetry-cat-smoke/lst-telemetry',
    platform: 'cat-webapp',
};

export const commonConfig: ICommonConfig = {
    recaptchaConfig,
    production: false,
    referrerAppUrl: 'https://self-registration-web.nightly.savvasrealizedev.com/',
    referrerAppLandingPageSubpath: 'register',
    customerAdminRole: 'Customer Admin',
    telemetryConfig,
    salesforceAdminRegistrationURL: 'https://support.savvas.com/support/s/administrator-request-support-form',
    savvasUrl: 'https://www.savvas.com',
    emailDomain: "savvas.com",
    oldSelfReg: '/email-validate.htm#/type/self'
};

export interface IEnvironment extends ICommonConfig {
    envName: string;
    envUrl?: string;
    aggregationUrl: string;
}

export const links = {
    privacyPolicyUrl: 'https://www.savvasrealize.com/privacy/corporate/privacy/learning-services-privacy-policy.html',
    userAgreementUrl: 'https://sso.rumba.pk12ls.com/sso/media/doc/realize/userAgreement.html',
    supportAndTrainingUrl: 'http://savvaseasybridge.com/support-training/',
    creditsUrl: 'https://media.pk12ls.com/curriculum/credits/index.html',
    savvasSupportUrl: 'https://support.savvas.com/support/s/contactsupport'
};
