import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { en } from './i18n/en';

const TRANSLATIONS = {
    en: en
};

// TODO  Have to refactor on https://github.com/ngx-translate/core/issues/962 bug fix
export class CustomTranslateLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return of(TRANSLATIONS[lang]);
    }
}
