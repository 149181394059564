import { Injectable } from "@angular/core";
import { gql, Mutation } from "apollo-angular";
import { verificationAssistance, verificationAssistanceVariables } from "../../../../schema/graphql-types";

@Injectable({ providedIn: 'root' })
export class SendVerifierRequestGQL extends Mutation<verificationAssistance, verificationAssistanceVariables> {
  document = gql`
    mutation verificationAssistance($registrationId: String!, $email: String!, $webSite: String!) {
      verificationAssistance(registrationId: $registrationId, email: $email, webSite: $webSite) {
        adminExists
        usOrganization
        statusCode
        status
        message
        }
    }
    `;
}
