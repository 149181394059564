import {Injectable} from "@angular/core";
import {gql, Mutation} from "apollo-angular";
import { SendAdminNotification, SendAdminNotificationVariables } from "../../../../schema/graphql-types";


@Injectable({providedIn: 'root'})
export class SendAdminNotificationGQL extends Mutation<SendAdminNotification, SendAdminNotificationVariables> {
    document = gql`
    mutation SendAdminNotification($registrationId: String!) {
      sendAdminNotification(registrationId: $registrationId){
        isAdminNotified
        statusCode
        status
        message
      }
    }
    `;
}
