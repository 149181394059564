<section class="register">
    <div class="register-header-container">
        <h1 class="account-registration">{{ 'teacherRegistration.accountRegisterHeader' | translate }}</h1>
        <p class="register-type-text">{{ 'teacherRegistration.registerTypeText' | translate }}</p>
    </div>
    <div class="register-container">
        <div class="register-panel" *ngFor="let registerUser of registrationList">
            <h2 class="register-header">{{ registerUser.header }}</h2>
            <p class="register-info-text">{{ registerUser.registerText }}</p>
            <cel-button class="register-button" data-regular="true" data-label="Register"
                (click)="navigateToRegistrationPage(registerUser.header)">
            </cel-button>
        </div>
    </div>
    <p class="student-login-text">
        <span class="students">{{'teacherRegistration.students' | translate}}</span>
        <span>{{ 'teacherRegistration.studentInfoText' | translate }}</span>
        <a class="signin-link" (click)="navigateToSignInPage()">{{'teacherRegistration.signInText' | translate}}</a>
    </p>
</section>
