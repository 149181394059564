import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationUtilsService } from './services/navigator-utils.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccountRegistrationPageComponent } from './components/account-registration-page/account-registration-page.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    providers: [
        NavigationUtilsService,
    ],
    exports: [TranslateModule],
    declarations: [
      AccountRegistrationPageComponent
    ]
})
export class SharedModule {
    constructor(private translate: TranslateService) {
        translate.setDefaultLang('en');
    }
}
