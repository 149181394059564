import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { map, Observable } from 'rxjs';
import {
    AdvanceOrganizationSearch,
    CreateSalesForceTicketRequest,
    searchOrganizationsForTeacher,
    SendVerificationCodeRequest
} from '../../../../schema/graphql-types';
import { SearchOrgInputFields, TeacherRegistrationRequest } from '../../types/types';
import { TeacherSelfRegistrationConstants } from '../constants/teacher-registration.constants';
import { CheckUsernameAvailabilityGQL } from '../queries/check-username-gql';
import { ResendVerifyTeacherRegistrationGQL } from "../queries/resend-verify-teacher-registration-gql";
import { SearchOrgGQL } from "../queries/search-org-gql";
import { SendAdminNotificationGQL } from '../queries/send-admin-notification-ggl';
import { SendTeacherRequestGQL } from '../queries/send-teacher-request-ggl';
import { TeacherRegistrationGQL } from "../queries/teacher-registration-gql";
import { UserAccountGQL } from "../queries/user-account-gql";
import { UserRegistrationGQL } from "../queries/user-registration-gql";
import { SendVerifierRequestGQL } from '../queries/verification-request-gql';
import { VerifyTeacherRegistrationGQL } from "../queries/verify-teacher-registration-gql";
import { TeacherRegistrationState } from "../store/state/teacher.registration.state";
import { CreateSalesForceTicketGQL } from '../queries/create-salesforce-ticket-gql';
import {CreateAccountGql} from "../queries/create-account-gql";
import {AdvanceOrganizationSearchGQL} from "../queries/advance-search-org-gql";
import {AeUserGQL} from "../queries/ae-user-gql";

@Injectable({
    providedIn: 'root'
})
export class TeacherRegistrationService {
    public registrationDetails: TeacherRegistrationState;

    constructor(
        private readonly searchOrgGQL: SearchOrgGQL,
        private readonly teacherRegistrationGQL: TeacherRegistrationGQL,
        private readonly userAccountGQL: UserAccountGQL,
        private readonly userRegistrationGQL: UserRegistrationGQL,
        private readonly verifyTeacherRegistrationGQL: VerifyTeacherRegistrationGQL,
        private readonly checkUsernameAvailabilityGQL: CheckUsernameAvailabilityGQL,
        private readonly resendVerifyTeacherRegistrationGQL: ResendVerifyTeacherRegistrationGQL,
        private readonly sendTeacherRequestGQL: SendTeacherRequestGQL,
        private readonly sendAdminNotificationGQL: SendAdminNotificationGQL,
        private readonly sendVerificationGQL: SendVerifierRequestGQL,
        private readonly createSalesforceTicketGQL: CreateSalesForceTicketGQL,
        private readonly createAccountGql: CreateAccountGql,
        private readonly advanceOrganizationSearchGQL: AdvanceOrganizationSearchGQL,
        private readonly aeUserGQL: AeUserGQL,) {
    }

    public searchOrganizations(searchOrgRequest: SearchOrgInputFields): Observable<ApolloQueryResult<searchOrganizationsForTeacher>> {
        return this.searchOrgGQL.watch(
            {
                orgName: searchOrgRequest.orgName,
                orgSearchType: TeacherSelfRegistrationConstants.orgType.schoolOnly,
                orgPostalCode: searchOrgRequest.orgPostalCode,
                orgState: searchOrgRequest.orgState,
                orgCity: searchOrgRequest.orgCity,
                orgCountry: searchOrgRequest.orgCountry
            },
            { fetchPolicy: 'network-only' })
            .valueChanges.pipe(map(response => response));
    }

    public advanceSearchOrganizations(orgIds: string[]): Observable<ApolloQueryResult<AdvanceOrganizationSearch>> {
        return this.advanceOrganizationSearchGQL.watch(
            {
                orgIds: orgIds,
                reportAdvanceSearch: false,
                rootOrg: false
            },
            { fetchPolicy: 'network-only' })
            .valueChanges.pipe(map(response => response));
    }

    public teacherRegistration(teacherRegistrationRequest: TeacherRegistrationRequest) {
        return this.teacherRegistrationGQL
            .mutate(
                {
                    teacherRegistrationRequest: teacherRegistrationRequest,
                },
                { fetchPolicy: 'network-only' }
            );
    }

    public verifyTeacherRegistration(verificationCode: string, teacherRegistrationRequest: TeacherRegistrationRequest) {
        return this.verifyTeacherRegistrationGQL
            .mutate(
                {
                    verificationCode,
                    teacherRegistrationRequest
                },
                { fetchPolicy: 'network-only' }
            );
    }

    public getUserAccount(email: string, organizationId: string) {
        return this.userAccountGQL.mutate(
            {
                email,
                organizationId
            },
            { fetchPolicy: 'network-only' });
    }

    public getUserRegistration(registrationId: string) {
        return this.userRegistrationGQL.mutate(
            {
                registrationId
            },
            { fetchPolicy: 'network-only' });
    }

    public isUsernameAvailable(username: string) {
        return this.checkUsernameAvailabilityGQL.fetch({ username },
            { errorPolicy: 'all', fetchPolicy: 'network-only' }
        );
    }

    public getAeUser(userId: string) {
        return this.aeUserGQL.fetch({ userId },
            { errorPolicy: 'all', fetchPolicy: 'network-only' }
        );
    }

    public createAccount(registrationId: string, userName: string) {
        return this.createAccountGql.mutate(
            {
                registrationId,
                userName
            },
            { fetchPolicy: 'network-only' });
    }

    public resendVerifyTeacherRegistration(resendVerificationRequest: SendVerificationCodeRequest) {
        return this.resendVerifyTeacherRegistrationGQL
            .mutate(
                {
                    resendVerificationRequest
                },
                { fetchPolicy: 'network-only' }
            );
    }

    public sendTeacherApprovalRequest(registrationId: string, email: string) {
        return this.sendTeacherRequestGQL.mutate(
            {
                registrationId,
                email
            },
            { fetchPolicy: 'network-only' });
    }

    public sendAdminApprovalRequest(registrationId: string) {
        return this.sendAdminNotificationGQL.mutate(
            {
                registrationId
            },
            { fetchPolicy: 'network-only' });
    }

    public sendVerifierRequest(registrationId: string, email: string, webSite: string) {
        return this.sendVerificationGQL.mutate(
            {
                registrationId,
                email,
                webSite
            },
            { fetchPolicy: 'network-only' });
    }

    public createSalesForceTicket(salesForceTicketParams: CreateSalesForceTicketRequest) {
        return this.createSalesforceTicketGQL.fetch({
            salesForceTicketParams: salesForceTicketParams
        },
            { errorPolicy: 'all', fetchPolicy: 'network-only' }
        );
    }
}
