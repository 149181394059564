import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { createSalesForceTicket, createSalesForceTicketVariables } from "../../../../schema/graphql-types";

@Injectable({ providedIn: 'root' })
export class CreateSalesForceTicketGQL extends Query<createSalesForceTicket, createSalesForceTicketVariables> {
    document = gql` 
    query createSalesForceTicket($salesForceTicketParams: CreateSalesForceTicketRequest!) {
        createSalesForceTicket(salesForceTicketParams: $salesForceTicketParams)
    }`;
}
