import { Injectable } from "@angular/core";
import { gql, Query } from "apollo-angular";
import {
    AdvanceOrganizationSearch, AdvanceOrganizationSearchVariables,
    checkAeUserNameAvailability,
    checkAeUserNameAvailabilityVariables, getUser, GetUserAccountVariables, getUserVariables
} from "../../../../schema/graphql-types";

@Injectable({ providedIn: 'root' })
export class AeUserGQL extends Query<getUser, getUserVariables> {
    document = gql`
    query getUser($userId: String!) {
            getUser(userId: $userId) {
                 userId,
                 firstName,
                 middleName,
                 lastName,
                 displayName,
                 gender,
                 userName,
                 userStatus
            }
    }`;
}
