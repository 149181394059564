import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import {
    TeacherRegistrationDetailsService
} from "../../../teacher-self-registration/services/teacher-registration-details.service";
import { RegistrationInfo, RegistrationInfoService } from '../../services/registration-info.service';

@Component({
    selector: 'account-registration-page',
    templateUrl: './account-registration-page.component.html',
    styleUrls: ['./account-registration-page.component.scss']
})
export class AccountRegistrationPageComponent {

    registrationList = [{
        header: this.translate.instant('teacherRegistration.teacherHeader'),
        registerText: this.translate.instant('teacherRegistration.registerTeacherText')
    },
    {
        header: this.translate.instant('teacherRegistration.adminHeader'),
        registerText: this.translate.instant('teacherRegistration.registerAdminText')
    }];

    constructor(
        private readonly router: Router,
        private readonly translate: TranslateService,
        private readonly registrationDetailsService: TeacherRegistrationDetailsService,
        private registrationInfoService: RegistrationInfoService) {
    }

    navigateToRegistrationPage(role: string): void {
        if (role == 'Teachers') {
            this.registrationDetailsService.clearRegistrationDetailsFromStore();
            this.router.navigate(['teacherselfregistration'])
        }
        if (role == 'Administrators') {
            const registrationInfo: RegistrationInfo = this.registrationInfoService?.getSelfRegistrationInfo();
            if (registrationInfo?.firstName || registrationInfo?.lastName || registrationInfo?.email) {
                this.registrationInfoService?.setUserInfo(null, null, null);
            }
            this.router.navigate(['adminselfregistration'])
        }
    }

    navigateToSignInPage(): void {
        window.open(`${environment.ssoUrl}/login?additionalAttributes=Domains&k12int=true&profile=eb&service=${environment.catUrl}`)
    }

}
