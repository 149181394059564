export const TeacherSelfRegistrationConstants = {
    unitedStates: "US",
    minLen: 4,
    maxLen: 5,
    emailSeparator: '@',
    null: 'null',
    slash: '../',
    doubleSlash: '../../',
    components: {
        schoolSearchCard: "schoolSearchCard",
        teacherRegistration: 'teacherRegistration',
        confirmTeacherRegistration: 'confirmTeacherRegistration',
        createUsername: 'createUsername',
        verifyEmail: 'verifyEmail',
        approveRegistration: 'approveRegistration',
        registrationComplete: 'registrationComplete'
    },
    regExp: {
        numbers: new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/),
        email: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
        url: new RegExp(/^\S+\d*\.+\S+\d*$/)
    },
    orgType: {
        schoolOnly: "SCHOOL_ONLY",
        districtOnly: 'DISTRICT_ONLY',
        school: "SCHOOL",
        district: 'DISTRICT',
    },
    registrationStatus: {
        notFoundErrCode: 'NOT_FOUND',
        rejectedErrCode: 'rejected',
        createdErrCode: 'created',
        createdCode: 'created',
        approvedCode: 'approved',
        notVerifiedStatus: 'notverified',
        invitedStatus: 'invited',
        requestedStatus: 'requested',
        httpStatusOk: 'OK',
        provisionalStatus: 'provisional',
        incompleteStatus: 'incompleted',
        bouncedStatus: 'bounced',
    },
    registrationId: "registrationId",
    organizationId: "organizationId",
    hasAdmin: 'hasAdmin',
    from: "from",
    successResponse: '200',
    preConditionFailedStatus: '412',
    fromPage: {
        confirmPage: "confirmPage",
        verifyEmailPage: "verifyEmailPage",
        createUserPage: "createUserPage",
        accountExistPage: 'accountExistPage',
        approvalpage: 'approvalPage',
        verifyUserPage: 'VerifyUserPage'
    },
    badRequest: 'Bad Request',
    preConditionFailed: 'Precondition Failed',
    teacherInvite: 'teacher-invite'
}

export const NavigateTo = {
    register: 'register',
    teacherRegistration: 'teacher-registration',
    schoolSearchCard: 'request-account',
    schoolSelection: 'school-selection',
    confirmTeacherRegistration: 'confirm-registration',
    createUsername: 'create-username',
    verifyEmail: 'verify-email',
    approveTeacher: 'approval',
    undeliveredEmail: 'undeliverable-email',
    teacherInvite: 'teacher-invite',
    confirmTeacherRequestApproval: '../../confirm-teacher-approval',
    tryAgain: 'error',
    error: '../error',
    preVerifyEmail: '../../../verify-email',
    preCreateUsername: '../../../create-username',
    preRegister: '../../../../register',
    requestedRegistration: '../requestedRegistration',
    requestedRegistrationToConfirm: '../../confirm-registration',
    alreadyExistingAccount: 'already-account-exist',
    incompleteRegistration: '../incompleteRegistration',
    approvalPage: '../../approval',
    backToVerifyEmailFromResendCodeError: '../../verify-email',
    adminRequestSentPage: 'confirm-admin-approval',
    teacherRequestSentPage: 'confirm-teacher-approval',
    verifyUserPage: 'verify-user',
    adminConfirmationPage: 'confirm-admin-approval',
    registrationComplete: 'registration-complete',
    cancelRequestPage: 'cancel-invitation'
}

export const UserStatusValue = {
    ACTIVE: 'A',
    SUSPENDED: 'S',
    DELETED: 'D',
    ACTIVESTATUS: 'ACTIVE',
    SUSPENDEDSTATUS: 'SUSPENDED',
    USER_NOT_FOUND: 'User not found'
}
