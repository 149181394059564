/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: searchOrganizations
// ====================================================

export interface searchOrganizations_searchOrganizations_orgSettings {
  __typename: "SelfRegistration";
  selfRegistration: string | null;
}

export interface searchOrganizations_searchOrganizations {
  __typename: "Organization";
  name: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  country: string | null;
  organizationId: string;
  displayName: string | null;
  organizationType: string | null;
  orgSettings: searchOrganizations_searchOrganizations_orgSettings | null;
}

export interface searchOrganizations {
  searchOrganizations: searchOrganizations_searchOrganizations[];
}

export interface searchOrganizationsVariables {
  orgName: string;
  orgSearchType: string;
  orgPostalCode: string;
  searchLimit?: number | null;
  orgState?: string | null;
  orgCity?: string | null;
  orgCountry?: string | null;
  sapCustomerId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: selfRegistration
// ====================================================

export interface selfRegistration_selfRegistration_response {
  __typename: "UserAccount";
  registrationId: string | null;
  message: string | null;
  status: string | null;
  userExists: boolean | null;
  role: string | null;
  adminExists: boolean | null;
  registrationExists: boolean | null;
  response: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  requestDate: string;
  accessRequestedType: string;
}

export interface selfRegistration_selfRegistration {
  __typename: "UserAccountResponse";
  response: selfRegistration_selfRegistration_response | null;
  status: string | null;
  statusCode: string | null;
}

export interface selfRegistration {
  selfRegistration: selfRegistration_selfRegistration;
}

export interface selfRegistrationVariables {
  email: string;
  firstName: string;
  lastName: string;
  orgID: string;
  orgName: string;
  userRole: string;
  userId?: string | null;
  userName?: string | null;
  orgType?: string | null;
  recaptchaResponse?: string | null;
  policy: Policy[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getActiveAdminsCount
// ====================================================

export interface getActiveAdminsCount_getActiveAdminsCount {
  __typename: "ActiveAdmins";
  count: number | null;
  statusCode: string | null;
  status: string | null;
}

export interface getActiveAdminsCount {
  getActiveAdminsCount: getActiveAdminsCount_getActiveAdminsCount;
}

export interface getActiveAdminsCountVariables {
  organizationId: string;
  userRole: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: salesforceSelfRegistration
// ====================================================

export interface salesforceSelfRegistration_salesforceSelfRegistration {
  __typename: "SalesforceCaseResponse";
  salesforceCaseNumber: string | null;
  status: string | null;
  statusCode: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface salesforceSelfRegistration {
  salesforceSelfRegistration: salesforceSelfRegistration_salesforceSelfRegistration;
}

export interface salesforceSelfRegistrationVariables {
  email: string;
  firstName: string;
  lastName: string;
  orgInfo: OrganizationInput;
  organizationUrl?: string | null;
  recaptchaResponse?: string | null;
  superintendent?: ContactInfo | null;
  techDirector?: ContactInfo | null;
  principal?: ContactInfo | null;
  policy: Policy[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllUserPolicies
// ====================================================

export interface getAllUserPolicies_getAllUserPolicies_userPolicies {
  __typename: "UserPolicy";
  policyId: string;
  longTitle: string;
  description: string;
  defaultPolicy: string[] | null;
  active: boolean | null;
}

export interface getAllUserPolicies_getAllUserPolicies {
  __typename: "UserPolicyResponse";
  userPolicies: getAllUserPolicies_getAllUserPolicies_userPolicies[] | null;
  status: string | null;
  statusCode: string | null;
}

export interface getAllUserPolicies {
  getAllUserPolicies: getAllUserPolicies_getAllUserPolicies;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdvanceOrganizationSearch
// ====================================================

export interface AdvanceOrganizationSearch_advanceOrganizationSearch_orgSettings {
  __typename: "SelfRegistration";
  selfRegistration: string | null;
}

export interface AdvanceOrganizationSearch_advanceOrganizationSearch {
  __typename: "Organization";
  name: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  country: string | null;
  organizationId: string;
  displayName: string | null;
  organizationType: string | null;
  orgSettings: AdvanceOrganizationSearch_advanceOrganizationSearch_orgSettings | null;
}

export interface AdvanceOrganizationSearch {
  advanceOrganizationSearch: AdvanceOrganizationSearch_advanceOrganizationSearch[];
}

export interface AdvanceOrganizationSearchVariables {
  orgIds: string[];
  reportAdvanceSearch: boolean;
  rootOrg: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUser
// ====================================================

export interface getUser_getUser {
  __typename: "AEUser";
  userId: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  displayName: string | null;
  gender: GenderType;
  userName: string | null;
  userStatus: UserStatus | null;
}

export interface getUser {
  getUser: getUser_getUser;
}

export interface getUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkAeUserNameAvailability
// ====================================================

export interface checkAeUserNameAvailability_checkUsernameAvailability {
  __typename: "UsernameAvailabilityResponse";
  isAvailable: boolean;
}

export interface checkAeUserNameAvailability {
  checkUsernameAvailability: checkAeUserNameAvailability_checkUsernameAvailability;
}

export interface checkAeUserNameAvailabilityVariables {
  username: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompleteSelfRegistration
// ====================================================

export interface CompleteSelfRegistration_completeSelfRegistration {
  __typename: "UserAccoutUpdateResponse";
  registrationId: string | null;
  userId: string | null;
  status: string | null;
  statusCode: string | null;
}

export interface CompleteSelfRegistration {
  completeSelfRegistration: CompleteSelfRegistration_completeSelfRegistration;
}

export interface CompleteSelfRegistrationVariables {
  registrationId: string;
  userName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: createSalesForceTicket
// ====================================================

export interface createSalesForceTicket {
  createSalesForceTicket: string;
}

export interface createSalesForceTicketVariables {
  salesForceTicketParams: CreateSalesForceTicketRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendVerifyTeacherRegistration
// ====================================================

export interface ResendVerifyTeacherRegistration_resendVerifyUserRegistration {
  __typename: "SendVerificationCodeResponse";
  response: string | null;
  registrationId: string | null;
  autoVerificationMode: string | null;
  autoVerification: boolean | null;
  skipTeacherVerification: boolean | null;
  teacherExists: boolean | null;
  adminExists: boolean | null;
  registrationExists: boolean | null;
  status: string | null;
  message: string | null;
  userExists: boolean | null;
  role: string | null;
  statusCode: string | null;
}

export interface ResendVerifyTeacherRegistration {
  resendVerifyUserRegistration: ResendVerifyTeacherRegistration_resendVerifyUserRegistration;
}

export interface ResendVerifyTeacherRegistrationVariables {
  resendVerificationRequest: SendVerificationCodeRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: searchOrganizationsForTeacher
// ====================================================

export interface searchOrganizationsForTeacher_searchOrganizations_orgSettings {
  __typename: "SelfRegistration";
  selfRegistration: string | null;
}

export interface searchOrganizationsForTeacher_searchOrganizations {
  __typename: "Organization";
  name: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  country: string | null;
  organizationId: string;
  displayName: string | null;
  organizationType: string | null;
  orgSettings: searchOrganizationsForTeacher_searchOrganizations_orgSettings | null;
}

export interface searchOrganizationsForTeacher {
  searchOrganizations: searchOrganizationsForTeacher_searchOrganizations[];
}

export interface searchOrganizationsForTeacherVariables {
  orgName: string;
  orgSearchType: string;
  orgPostalCode?: string | null;
  searchLimit?: number | null;
  orgState?: string | null;
  orgCity?: string | null;
  orgCountry?: string | null;
  sapCustomerId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendAdminNotification
// ====================================================

export interface SendAdminNotification_sendAdminNotification {
  __typename: "SendAdminNotificationResponse";
  isAdminNotified: boolean;
  statusCode: string | null;
  status: string | null;
  message: string | null;
}

export interface SendAdminNotification {
  sendAdminNotification: SendAdminNotification_sendAdminNotification;
}

export interface SendAdminNotificationVariables {
  registrationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendTeacherApproval
// ====================================================

export interface SendTeacherApproval_teacherVerification {
  __typename: "VerifyTeacherResponse";
  registrationId: string | null;
  userId: string | null;
  status: string | null;
  organizationId: string | null;
  districtOrgId: string | null;
  statusCode: string | null;
}

export interface SendTeacherApproval {
  teacherVerification: SendTeacherApproval_teacherVerification;
}

export interface SendTeacherApprovalVariables {
  registrationId: string;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TeacherRegistration
// ====================================================

export interface TeacherRegistration_teacherRegistration {
  __typename: "TeacherRegistrationResponse";
  response: string | null;
  registrationId: string | null;
  autoVerificationMode: string | null;
  autoVerification: boolean | null;
  skipTeacherVerification: boolean | null;
  teacherExists: boolean | null;
  adminExists: boolean | null;
  registrationExists: boolean | null;
  status: string | null;
  message: string | null;
  userExists: boolean | null;
  role: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  statusCode: string | null;
  userDeleted: boolean | null;
}

export interface TeacherRegistration {
  teacherRegistration: TeacherRegistration_teacherRegistration;
}

export interface TeacherRegistrationVariables {
  teacherRegistrationRequest: TeacherRegistrationRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetUserAccount
// ====================================================

export interface GetUserAccount_getUserAccount_userAccounts {
  __typename: "UserAccounts";
  userName: string | null;
  name: string | null;
  schoolName: string | null;
  userId: string | null;
  organizationId: string | null;
  registrationId: string | null;
  userStatus: string | null;
  createdDate: string | null;
}

export interface GetUserAccount_getUserAccount {
  __typename: "UserAccountsResponse";
  userAccounts: GetUserAccount_getUserAccount_userAccounts[] | null;
  message: string | null;
  statusCode: string | null;
}

export interface GetUserAccount {
  getUserAccount: GetUserAccount_getUserAccount;
}

export interface GetUserAccountVariables {
  email: string;
  organizationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetUserRegistration
// ====================================================

export interface GetUserRegistration_getUserRegistration_settings {
  __typename: "Settings";
  dateLastModified: string | null;
  isDeleted: string | null;
  districtId: string | null;
  emails: string[] | null;
  userEmail: string | null;
  userId: string | null;
  userFullName: string | null;
  role: string | null;
  existingTeacherVerification: boolean;
  teacherDomainVerification: boolean;
  teacherMDRVerification: boolean;
  domainCheck: boolean;
}

export interface GetUserRegistration_getUserRegistration_registration {
  __typename: "Registration";
  registrationId: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  approvalDate: string | null;
  approvedBy: string | null;
  rejectedDate: string | null;
  rejectedBy: string | null;
  completeDate: string | null;
  expirationDate: string | null;
  dateLastModified: string | null;
  organizationName: string | null;
  organizationId: string | null;
  status: string | null;
  invitationSentBy: string | null;
  autoVerified: boolean;
  autoVerifiedBy: string | null;
  verificationAssistance: boolean;
  schoolWebsite: string | null;
  adminEmail: string | null;
  registrationDenyType: string | null;
  invitationDate: string | null;
  notifiedTeacherEmail: string | null;
  tmsMatchResult: string | null;
  tmsMatchConfidence: string | null;
  isDeleted: string | null;
  userId: string | null;
  districtOrgId: string | null;
  supportApproved: boolean;
  teacherAssistance: boolean;
  userType: string | null;
  reminderSendCount: string | null;
  teacherReminderSendCount: string | null;
  requestDate: string | null;
  verificationCode: string | null;
  verificationExpirationDate: string | null;
  verifiedFlag: boolean;
  skipTeacherVerification: boolean;
  registrationStatusAudit: string | null;
  incompleteMailSent: boolean;
  accessRequestType: string | null;
  orgIds: string | null;
  fullName: string | null;
  userRequestPolicy: string | null;
}

export interface GetUserRegistration_getUserRegistration {
  __typename: "UserRegistrationResponse";
  adminExists: boolean | null;
  teacherExists: boolean | null;
  address1: string | null;
  address2: string | null;
  countryCode: string | null;
  city: string | null;
  stateCode: string | null;
  postalCode: string | null;
  settings: GetUserRegistration_getUserRegistration_settings | null;
  registration: GetUserRegistration_getUserRegistration_registration | null;
}

export interface GetUserRegistration {
  getUserRegistration: GetUserRegistration_getUserRegistration;
}

export interface GetUserRegistrationVariables {
  registrationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verificationAssistance
// ====================================================

export interface verificationAssistance_verificationAssistance {
  __typename: "VerificationAssistanceResponse";
  adminExists: boolean | null;
  usOrganization: boolean | null;
  statusCode: string | null;
  status: string | null;
  message: string | null;
}

export interface verificationAssistance {
  verificationAssistance: verificationAssistance_verificationAssistance;
}

export interface verificationAssistanceVariables {
  registrationId: string;
  email: string;
  webSite: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VerifyTeacherRegistration
// ====================================================

export interface VerifyTeacherRegistration_verifyTeacherRegistration {
  __typename: "TeacherRegistrationResponse";
  response: string | null;
  registrationId: string | null;
  autoVerificationMode: string | null;
  autoVerification: boolean | null;
  skipTeacherVerification: boolean | null;
  teacherExists: boolean | null;
  adminExists: boolean | null;
  registrationExists: boolean | null;
  status: string | null;
  message: string | null;
  userExists: boolean | null;
  role: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  statusCode: string | null;
}

export interface VerifyTeacherRegistration {
  verifyTeacherRegistration: VerifyTeacherRegistration_verifyTeacherRegistration;
}

export interface VerifyTeacherRegistrationVariables {
  verificationCode: string;
  teacherRegistrationRequest: TeacherRegistrationRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum GenderType {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNSPECIFIED = "UNSPECIFIED",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  LOCKED = "LOCKED",
  SUSPENDED = "SUSPENDED",
}

export interface ContactInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface CreateSalesForceTicketRequest {
  email: string;
  verifyAdminEmail: string;
  verifyUrl: string;
  teacherName: string;
  teacherSchool: string;
  organizationId: string;
  districtId: string;
}

export interface OrganizationInput {
  name: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  id: string;
  type: string;
}

export interface Policy {
  active: boolean;
  policyId: string;
  longTitle: string;
  description: string;
  defaultPolicy?: string[] | null;
}

export interface SendVerificationCodeRequest {
  email: string;
  firstName: string;
  lastName: string;
  userName?: string | null;
  userId?: string | null;
  organizationId: string;
  userRegistrationId?: string | null;
  autoVerificationMode?: string | null;
  autoVerification?: boolean | null;
  skipTeacherVerification?: boolean | null;
  teacherExists?: boolean | null;
  adminExists?: boolean | null;
  organizationName?: string | null;
  organizationIds?: string[] | null;
  districtId?: string | null;
  address1?: string | null;
  countryCode?: string | null;
  isNewAccountCreated?: boolean | null;
}

export interface TeacherRegistrationRequest {
  email: string;
  firstName: string;
  lastName: string;
  userName?: string | null;
  userId?: string | null;
  organizationId: string;
  userRegistrationId?: string | null;
  autoVerificationMode?: string | null;
  autoVerification?: boolean | null;
  skipTeacherVerification?: boolean | null;
  teacherExists?: boolean | null;
  adminExists?: boolean | null;
  organizationName?: string | null;
  organizationIds?: string[] | null;
  districtId?: string | null;
  address1?: string | null;
  countryCode?: string | null;
  isNewAccountCreated?: boolean | null;
  userExists?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
