import {Injectable} from "@angular/core";
import {gql, Mutation} from "apollo-angular";
import {
    GetUserRegistration,
    GetUserRegistrationVariables,
} from "../../../../schema/graphql-types";

@Injectable({providedIn: 'root'})
export class UserRegistrationGQL extends Mutation<GetUserRegistration, GetUserRegistrationVariables> {
    document = gql`
    mutation GetUserRegistration($registrationId: String!) {
        getUserRegistration(registrationId: $registrationId) {
            adminExists,
            teacherExists,
            address1,
            address2,
            countryCode,
            city,
            stateCode,
            postalCode,
            settings {
                dateLastModified,
                isDeleted,
                districtId,
                emails,
                userEmail,
                userId,
                userFullName,
                role,
                existingTeacherVerification,
                teacherDomainVerification,
                teacherMDRVerification,
                domainCheck
            },
            registration {
                 registrationId,
                 email,
                 firstName,
                 lastName,
                 approvalDate,
                 approvedBy,
                 rejectedDate,
                 rejectedBy,
                 completeDate,
                 expirationDate,
                 dateLastModified,
                 organizationName,
                 organizationId,
                 status,
                 invitationSentBy,
                 autoVerified,
                 autoVerifiedBy,
                 verificationAssistance,
                 schoolWebsite,
                 adminEmail,
                 registrationDenyType,
                 invitationDate,
                 notifiedTeacherEmail,
                 tmsMatchResult,
                 tmsMatchConfidence,
                 isDeleted,
                 userId,
                 districtOrgId,
                 supportApproved,
                 teacherAssistance,
                 userType,
                 reminderSendCount,
                 teacherReminderSendCount,
                 requestDate,
                 verificationCode,
                 verificationExpirationDate,
                 verifiedFlag,
                 skipTeacherVerification,
                 registrationStatusAudit,
                 incompleteMailSent,
                 accessRequestType,
                 orgIds,
                 fullName,
                 userRequestPolicy
            }
        }
    }
    `;
}
