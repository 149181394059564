import {Injectable} from "@angular/core";
import {gql, Mutation} from "apollo-angular";
import {
    CompleteSelfRegistration,
    CompleteSelfRegistrationVariables,
    TeacherRegistration,
    TeacherRegistrationVariables
} from "../../../../schema/graphql-types";

@Injectable({providedIn: 'root'})
export class CreateAccountGql extends Mutation<CompleteSelfRegistration, CompleteSelfRegistrationVariables> {
    document = gql`
    mutation CompleteSelfRegistration($registrationId: String!, $userName: String!) {
      completeSelfRegistration(registrationId: $registrationId, userName: $userName){
        registrationId
        userId
        status
        statusCode
      }
    }
    `;
}
