import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import {
    AdvanceOrganizationSearch,
    AdvanceOrganizationSearchVariables,
} from '../../../../schema/graphql-types';

@Injectable({ providedIn: 'root' })
export class AdvanceOrganizationSearchGQL extends Query<AdvanceOrganizationSearch, AdvanceOrganizationSearchVariables>   {
    document = gql`
    query AdvanceOrganizationSearch (
        $orgIds: [String!]!,
        $reportAdvanceSearch: Boolean!,
        $rootOrg: Boolean!) {
        advanceOrganizationSearch(advanceOrgSearchRequest: {
            orgIds: $orgIds,
            reportAdvanceSearch: $reportAdvanceSearch,
            rootOrg: $rootOrg
        }){
            name,
            address1,
            address2,
            city,
            state,
            zipcode,
            country,
            organizationId,
            displayName,
            organizationType
            orgSettings {
                selfRegistration
            }
        }
    }
`;
}
